// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/offerWallsAction';

export const INITIAL_STATE = Immutable({
  offerWallsData: {
    total: 0,
    offerWalls: []
  },
  isLoading: false,
  error: null,
  addSectionError: null,
  setStatusErr: null,
  deleteSectionErr: null,
  updateSectionErr: null
});

/**
 * Get list of offer wall request
 * @param {*} state
 * @returns isLoading
 */
const getListOfferWallsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getListOfferWallsSuccess = (state, action) => {
  const {
    // eslint-disable-next-line camelcase
    data: { total, offer_walls }
  } = action;

  return merge(state, {
    isLoading: false,
    offerWallsData: {
      total,
      offerWalls: offer_walls
    },
    error: null
  });
};

const getListOfferWallsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    offerWallsData: {
      total: 0,
      offerWalls: []
    }
  });
};

const addSectionOfferWallRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addSectionError: null,
    setStatusErr: null,
    error: null,
    deleteSectionErr: null,
    updateSectionErr: null
  });
};

const addSectionOfferWallSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    addSectionError: null
  });
};

const addSectionOfferWallFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    addSectionError: error
  });
};

const refreshCategoriesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addSectionError: null,
    setStatusErr: null,
    error: null,
    deleteSectionErr: null,
    updateSectionErr: null
  });
};

const refreshCategoriesSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    offerWallsData: {
      ...state.offerWallsData,
      offerWalls: data
    }
  });
};

const refreshCategoriesFailed = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const updateOfferWallRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addSectionError: null,
    setStatusErr: null,
    error: null
  });
};

const updateOfferWallSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const updateOfferWallFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    setStatusErr: error
  });
};

/**
 * Delete Section in Offer Wall
 * @param {*} state
 * @returns
 */
const deleteSectionOfferWallRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addSectionError: null,
    setStatusErr: null,
    error: null,
    deleteSectionErr: null,
    updateSectionErr: null
  });
};

const deleteSectionOfferWallSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const deleteSectionOfferWallFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    deleteSectionErr: error
  });
};

/**
 * Update Section in Offer Wall
 * @param {*} state
 * @returns
 */
const updateSectionOfferWallRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addSectionError: null,
    setStatusErr: null,
    error: null,
    deleteSectionErr: null,
    updateSectionErr: null
  });
};

const updateSectionOfferWallSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const updateSectionOfferWallFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    updateSectionErr: error
  });
};

export const HANDLERS = {
  [Types.GET_LIST_OFFER_WALLS_REQUEST]: getListOfferWallsRequest,
  [Types.GET_LIST_OFFER_WALLS_SUCCESS]: getListOfferWallsSuccess,
  [Types.GET_LIST_OFFER_WALLS_FAILED]: getListOfferWallsFailed,

  [Types.ADD_SECTION_OFFER_WALL_REQUEST]: addSectionOfferWallRequest,
  [Types.ADD_SECTION_OFFER_WALL_SUCCESS]: addSectionOfferWallSuccess,
  [Types.ADD_SECTION_OFFER_WALL_FAILED]: addSectionOfferWallFailed,

  [Types.REFRESH_CATEGORIES_REQUEST]: refreshCategoriesRequest,
  [Types.REFRESH_CATEGORIES_SUCCESS]: refreshCategoriesSuccess,
  [Types.REFRESH_CATEGORIES_FAILED]: refreshCategoriesFailed,

  [Types.UPDATE_OFFER_WALL_REQUEST]: updateOfferWallRequest,
  [Types.UPDATE_OFFER_WALL_SUCCESS]: updateOfferWallSuccess,
  [Types.UPDATE_OFFER_WALL_FAILED]: updateOfferWallFailed,

  [Types.DELETE_SECTION_OFFER_WALL_REQUEST]: deleteSectionOfferWallRequest,
  [Types.DELETE_SECTION_OFFER_WALL_SUCCESS]: deleteSectionOfferWallSuccess,
  [Types.DELETE_SECTION_OFFER_WALL_FAILED]: deleteSectionOfferWallFailed,

  [Types.UPDATE_SECTION_OFFER_WALL_REQUEST]: updateSectionOfferWallRequest,
  [Types.UPDATE_SECTION_OFFER_WALL_FAILED]: updateSectionOfferWallSuccess,
  [Types.UPDATE_SECTION_OFFER_WALL_FAILED]: updateSectionOfferWallFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
