// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from 'src/redux/actions/adminUsersAction';

export const INITIAL_STATE = Immutable({
  adminUsersData: {
    total: 0,
    adminUsers: []
  },
  isLoading: false,
  adminUserDetails: {
  },
  error: null,
  updateError: null,
  errorResetAdminPwd: null,
  errorUpdateAdminProfile: null
});

/**
 * Handle get request list user
 * @param {*} state
 * @returns
 */
const getListAdminUsersRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getListAdminUsersSuccess = (state, action) => {
  const {
    data: { total, adminUsers }
  } = action;

  return merge(state, {
    adminUsersData: {
      total,
      adminUsers
    },
    isLoading: false,
    error: {}
  });
};

const getListAdminUsersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    adminUsersData: {
      total: 0,
      users: []
    },
    error,
    isLoading: false
  });
};

/**
 * Create new user
 * @param {*} state
 * @returns
 */
const createAdminUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createAdminUserSuccess = (state, action) => {
  const { data } = action;
  const {
    adminUsersData: { adminUsers, total }
  } = state;

  return merge(state, {
    adminUsersData: {
      ...state.adminUsersData,
      total: total + 1,
      adminUsers: [data, ...adminUsers]
    },
    isLoading: false
  });
};

const createAdminUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

/**
 * Handle request update profile
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updateAdminProfileRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateAdminProfileSuccess = (state, action) => {
  const { data } = action;
  const newAdminUsers = [...state.adminUsersData.adminUsers];

  return merge(state, {
    // TODO: mocking users list after update. Call getListUsers when submit update
    adminUsersData: {
      ...state.adminUsersData,
      adminUsers: newAdminUsers.map((adminUser) =>
        adminUser.id === data.userID ? { id: adminUser.id, ...data } : adminUser
      )
    },
    isLoading: false,
    updateError: null
  });
};

const updateAdminProfileFailed = (state) => {
  return merge(state, {
    updateError: 'Error',
    isLoading: false
  });
};

const resetAdminPasswordRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const resetAdminPasswordSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    errorResetAdminPwd: null
  });
};

const resetAdminPasswordFailed = (state) => {
  return merge(state, {
    errorResetAdminPwd: 'Error',
    updateError: null,
    error: null,
    errorUpdateAdminProfile: null,
    isLoading: false
  });
};

const cancelAdminUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const cancelAdminUserSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const cancelAdminUserFailed = (state) => {
  return merge(state, {
    errorResetAdminPwd: null,
    updateError: null,
    error: 'error',
    errorUpdateAdminProfile: null,
    isLoading: false
  });
};
export const HANDLERS = {
  [Types.GET_LIST_ADMIN_USERS_REQUEST]: getListAdminUsersRequest,
  [Types.GET_LIST_ADMIN_USERS_SUCCESS]: getListAdminUsersSuccess,
  [Types.GET_LIST_ADMIN_USERS_FAILED]: getListAdminUsersFailed,

  [Types.CREATE_ADMIN_USER_REQUEST]: createAdminUserRequest,
  [Types.CREATE_ADMIN_USER_SUCCESS]: createAdminUserSuccess,
  [Types.CREATE_ADMIN_USER_FAILED]: createAdminUserFailed,

  [Types.UPDATE_ADMIN_PROFILE_REQUEST]: updateAdminProfileRequest,
  [Types.UPDATE_ADMIN_PROFILE_SUCCESS]: updateAdminProfileSuccess,
  [Types.UPDATE_ADMIN_PROFILE_FAILED]: updateAdminProfileFailed,

  [Types.RESET_ADMIN_PASSWORD_REQUEST]: resetAdminPasswordRequest,
  [Types.RESET_ADMIN_PASSWORD_SUCCESS]: resetAdminPasswordSuccess,
  [Types.RESET_ADMIN_PASSWORD_FAILED]: resetAdminPasswordFailed,

  [Types.CANCEL_ADMIN_USER_REQUEST]: cancelAdminUserRequest,
  [Types.CANCEL_ADMIN_USER_SUCCESS]: cancelAdminUserSuccess,
  [Types.CANCEL_ADMIN_USER_FAILED]: cancelAdminUserFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
