// libs
import { combineReducers } from 'redux';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

// action
import { Types } from 'src/redux/actions/authAction';

// constant
import { PERSIST_KEY } from 'src/constants/routers';

// reducers
import adminUsers from './adminUsersReducer';
import auth from './authReducer';
import brands from './brandsReducer';
import offers from './offersReducer';
import offerWalls from './offerWallsReducer';
import preshows from './preshowsReducer';
import rewards from './rewardsReducer';
import spots from './spotsReducer';
import users from './usersReducer';
import surveys from './surveysReducer';
import spotActions from './spotActionsReducer';
import items from './itemsReducer';

const usersPersistConfig = {
  key: 'users',
  storage,
  blacklist: ['userDetails']
};

const appReducer = combineReducers({
  adminUsersReducer: adminUsers,
  authReducer: auth,
  brandsReducer: brands,
  offersReducer: offers,
  offerWallsReducer: offerWalls,
  preshowsReducer: preshows,
  rewardsReducer: rewards,
  spotsReducer: spots,
  surveysReducer: surveys,
  spotActionsReducer: spotActions,
  itemsReducer: items,
  usersReducer: persistReducer(usersPersistConfig, users)
  // add more reducer to combine
});

const rootReducer = (state, action) => {
  if (action.type === Types.LOGOUT_REQUEST) {
    // reset the state of a Redux store
    storage.removeItem(`persist:${PERSIST_KEY}`);
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
