export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  NOTFOUND: '/404',
  USERS: '/users',
  OFFERS: '/offers',
  OFFER_WALLS: '/offerWalls',
  PRESHOWS: '/preshows',
  REWARDS: '/rewards',
  SPOTS: '/spots',
  SPOT_ACTIONS: '/spot_actions',
  SURVEYS: '/surveys',
  ITEMS: '/items',
  BRANDS: '/brands',
  ADMINISTRATORS: '/administrators'
};

export const NAVBAR_ROUTES = [
  {
    title: 'Users',
    icon: '/static/images/leftNav/users.png',
    iconActive: '/static/images/leftNav/users_selected.png',
    href: ROUTES.USERS
  },
  {
    title: 'Offers',
    icon: '/static/images/leftNav/offers.png',
    iconActive: '/static/images/leftNav/offers_selected.png',
    href: ROUTES.OFFERS
  },
  {
    title: 'Offer Walls',
    icon: '/static/images/leftNav/offerWall.png',
    iconActive: '/static/images/leftNav/offerWall_selected.png',
    href: ROUTES.OFFER_WALLS
  },
  {
    title: 'Preshows',
    icon: '/static/images/leftNav/preshows.png',
    iconActive: '/static/images/leftNav/preshows_selected.png',
    href: ROUTES.PRESHOWS
  },
  {
    title: 'Rewards',
    icon: '/static/images/leftNav/rewards.png',
    iconActive: '/static/images/leftNav/rewards_selected.png',
    href: ROUTES.REWARDS
  },
  {
    title: 'Spots',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.SPOTS
  },
  {
    title: 'Spot Actions',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.SPOT_ACTIONS
  },
  {
    title: 'Surveys',
    icon: '/static/images/leftNav/surveys.png',
    iconActive: '/static/images/leftNav/surveys_selected.png',
    href: ROUTES.SURVEYS
  },
  {
    title: 'Items',
    icon: '/static/images/leftNav/items.png',
    iconActive: '/static/images/leftNav/items_selected.png',
    href: ROUTES.ITEMS
  },
  {
    title: 'Brands',
    icon: '/static/images/leftNav/brands.png',
    iconActive: '/static/images/leftNav/brands_selected.png',
    href: ROUTES.BRANDS
  },
  {
    title: 'Administrators',
    icon: '/static/images/leftNav/administrators.png',
    iconActive: '/static/images/leftNav/administrators_selected.png',
    href: ROUTES.ADMINISTRATORS
  }
];

export const NAME_PAGES = [
  { value: 'rewards', name: 'Rewards' },
  { value: 'spots', name: 'Spots' },
  { value: 'surveys', name: 'Surveys' },
  { value: 'items', name: 'Items' },
  { value: 'brands', name: 'Brands' },
  { value: 'administrators', name: 'Administrators' }
];

export const PERSIST_KEY = 'preshow-admin';
