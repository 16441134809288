export default {
  // auth
  LOGIN: '/session',

  // brands
  BRANDS: '/brands',

  // users
  USERS: '/users',
  OFFER_FOR_ISSUE: '/offers_for_issue',
  RESET_PASSWORD: '/user_password_resets',
  CANCEL_USER: '/tasks',

  // offers
  OFFERS: '/offers',
  ADD_OFFER_REWARDS: '/offer_rewards',

  // offer walls
  OFFER_WALLS: '/offer_walls',
  OFFER_WALL_ELEMENTS: '/offer_wall_elements',
  REFRESH_CATEGORIES: 'refresh_cats',
  OFFER_WALL_SECTION: '/offer_wall_section',

  // preshows
  PRESHOWS: '/preshows',

  // admin users
  ADMIN_USERS: '/admin_users',

  // rewards
  OFFER_REWARDS: '/offer_rewards',

  // spots
  SPOTS: '/spots',

  // surveys
  SURVEYS: '/surveys',

  // spot actions
  SPOT_ACTIONS: '/spot_actions',

  // items
  ITEMS: '/items'
};
