// libs
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  Button,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';

// constant
import { NAVBAR_ROUTES } from 'src/constants/routers';

// components
import Logo from 'src/components/Logo';
import CreateList from 'src/components/CreateList';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 300,
    background: theme.palette.background.light,
    border: '1px solid #DEDEDE',
    overflowY: 'unset'
  },
  logo: {
    margin: theme.spacing(4.2, 0, 3.3, 3.5)
  },
  listNav: {
    padding: theme.spacing(2.1, 0, 0, 2.1),
    height: '100%',
    overflowY: 'scroll'
  },
  button: {
    margin: theme.spacing(0, 3.8, 0, 3.2),
    padding: theme.spacing(1.3, 0),
    textTransform: 'none',
    fontSize: 16,
    width: 231
  },
  userInfo: {
    padding: theme.spacing(2.0, 0),
    borderTop: '1px solid #BEC3C6',
    paddingLeft: 32
  },
  buttonLogout: {
    paddingLeft: 0,
    textTransform: 'none',
    '&:hover': {
      background: 'none'
    },
    fontSize: 16
  }
}));

const NavBar = ({ onMobileClose, openMobile, onLogout, nameUser }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [openList, setOpenList] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClickCreate = useCallback(() => {
    setOpenList(!openList);
  }, [openList]);

  const handleClickAway = useCallback(() => {
    setOpenList(false);
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <RouterLink to="/">
        <Logo width={228} height={40} className={classes.logo} />
      </RouterLink>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className={classes.button}
            onClick={handleClickCreate}
          >
            Create
          </Button>
          {openList && <CreateList />}
        </Box>
      </ClickAwayListener>
      <Box className={classes.listNav}>
        <List className={classes.root}>
          {NAVBAR_ROUTES.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={pathname.includes(item.href) ? item.iconActive : item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box className={classes.userInfo}>
        <Typography color="textPrimary" variant="h4">
          {nameUser}
        </Typography>
        <Button
          color="primary"
          onClick={onLogout}
          className={classes.buttonLogout}
        >
          Logout
        </Button>
      </Box>

      <Box />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  nameUser: PropTypes.string,
  onLogout: PropTypes.func,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string,
    roleName: PropTypes.string,
    roleCode: PropTypes.string,
    avatarUrl: PropTypes.string
  })
};

NavBar.defaultProps = {
  nameUser: '',
  onLogout: () => {},
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
