// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // get list of admin users
  getListAdminUsersRequest: ['params'],
  getListAdminUsersSuccess: ['data'],
  getListAdminUsersFailed: ['error'],

  // create admin user
  createAdminUserRequest: ['params', 'callback'],
  createAdminUserSuccess: ['data'],
  createAdminUserFailed: ['error'],

  // update an admin user
  updateAdminProfileRequest: ['params', 'callback'],
  updateAdminProfileSuccess: ['data'],
  updateAdminProfileFailed: ['error'],

  // reset the password of an admin user
  resetAdminPasswordRequest: ['params', 'callback'],
  resetAdminPasswordSuccess: ['data'],
  resetAdminPasswordFailed: ['error'],

  // cancel an admin user
  cancelAdminUserRequest: ['params', 'callback'],
  cancelAdminUserSuccess: ['data'],
  cancelAdminUserFailed: ['error']
});
