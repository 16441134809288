// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/spotActionsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  spotActionsData: {
    total: 0,
    spotActions: []
  }
});

const getListSpotActionsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListSpotActionsSuccess = (state, action) => {
  const {
    // eslint-disable-next-line camelcase
    data: { total, spot_actions }
  } = action;

  return merge(state, {
    isLoading: false,
    spotActionsData: {
      total,
      spotActions: spot_actions
    }
  });
};

const getListSpotActionsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    spotActionsData: {
      total: 0,
      spotActions: []
    }
  });
};

export const HANDLERS = {
  [Types.GET_LIST_SPOT_ACTIONS_REQUEST]: getListSpotActionsRequest,
  [Types.GET_LIST_SPOT_ACTIONS_SUCCESS]: getListSpotActionsSuccess,
  [Types.GET_LIST_SPOT_ACTIONS_FAILED]: getListSpotActionsFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
