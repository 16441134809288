// libs
import { all } from 'redux-saga/effects';

// sagas
import watchAdminUsersRequest from './adminUsersSaga';
import watchAuthRequest from './authSaga';
import watchBrandsRequest from './brandsSaga';
import watchOffersRequest from './offersSaga';
import watchOfferWallsRequest from './offerWallsSaga';
import watchPreshowsRequest from './preshowsSaga';
import watchRewardsRequest from './rewardsSaga';
import watchSpotsRequest from './spotsSaga';
import watchUsersRequest from './usersSaga';
import watchSurveysRequest from './surveysSaga';
import watchSpotActionsRequest from './spotActionsSaga';
import watchItemsRequest from './itemsSaga';

// create root saga
function* rootSaga() {
  yield all([
    watchAdminUsersRequest(),
    watchAuthRequest(),
    watchBrandsRequest(),
    watchOffersRequest(),
    watchOfferWallsRequest(),
    watchPreshowsRequest(),
    watchAdminUsersRequest(),
    watchRewardsRequest(),
    watchSpotsRequest(),
    watchUsersRequest(),
    watchSurveysRequest(),
    watchSpotActionsRequest(),
    watchItemsRequest()
  ]);
}

export default rootSaga;
