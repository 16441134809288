import cleanObject from 'src/helper/cleanObject';

import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to login with email and password
 *
 * @param {Objetc} param
 *
 * @returns
 */
export const login = async ({ email, password }) => {
  const response = await adminAPI.post(
    ENDPOINT.LOGIN,
    {
      email,
      password,
      kind: 'ADMIN'
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server to get list of user
 * @param {*} param
 * @returns response
 */
export const getListUsers = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.USERS, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

/**
 * handle request server to get list activity for user
 * @param {*} userID
 * @returns
 */
export const getActivities = async ({ userID, status }) => {
  const params = { key: status };
  const newParams = cleanObject(params, 'key');
  const response = await adminAPI.get(`users/${userID}/activities`, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get list rewards for user
 * @param {*} param0
 * @returns
 */
export const getRewards = async ({
  userID,
  key,
  status,
  offset = 0,
  limit = 10
} = {}) => {
  const params = { key, status, offset, limit };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(`users/${userID}/rewards`, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get list rewards for user
 * @param {*} param0
 * @returns
 */
export const getCardDetails = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/card`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to create new user
 * @param {*} param0
 * @returns
 */
export const createUser = async ({
  email,
  firstName,
  lastName,
  userName,
  password
}) => {
  const response = await adminAPI.post(
    ENDPOINT.USERS,
    {
      email,
      firstName,
      lastName,
      userName,
      password
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server to get list of user
 * @param {*} param
 * @returns response
 */
export const createCardNumber = async ({ reason, userId, adminId }) => {
  const response = await adminAPI.post(
    `users/${userId}/cards`,
    {
      reason,
      userId,
      adminId
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server update profile for user
 * @param {*} param0
 * @returns
 */
export const updateProfileUser = async ({
  userID,
  userName,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.USERS}/${userID}`,
    {
      userName,
      firstName,
      lastName,
      email,
      dateOfBirth,
      gender
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Get list of offer for issue
 * @returns
 */
export const getOfferForIssue = async () => {
  const response = await adminAPI.get(ENDPOINT.OFFER_FOR_ISSUE, {
    headers: authHeader()
  });

  return response;
};

/**
 * Create issue points for user
 * @param {*} param0
 * @returns
 */
export const createIssuePoint = async ({
  adminId,
  userId,
  offerId,
  preshowId,
  reason
}) => {
  const response = await adminAPI.post(
    `users/${userId}/rewards`,
    {
      adminId,
      userId,
      offerId,
      preshowId,
      reason
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle reset password request
 * @param {*} param0
 * @returns
 */
export const resetPassword = async ({ email }) => {
  const response = await adminAPI.post(
    ENDPOINT.RESET_PASSWORD,
    { email },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle cancel user request
 * @param {*} param0
 * @returns
 */
export const cancelUser = async ({ reason, userId, adminId, endDate }) => {
  const response = await adminAPI.post(
    ENDPOINT.CANCEL_USER,
    {
      reason,
      userId,
      adminId,
      action: 'CANCEL_USER',
      payload: { endDate }
    },
    { headers: authHeader() }
  );

  return response;
};
