import axios from 'axios';
import { store } from 'src/store';

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_TOKEN,
  REACT_APP_API_USER
} = process.env;

// Define API user authentication
export const adminAPI = axios.create({
  baseURL: `${REACT_APP_API_ENDPOINT}/admin/v1`,
  timeout: 30000
});

export const authHeader = () => {
  const auth = store.getState().authReducer || {};

  return {
    api_token: `${REACT_APP_API_TOKEN}`,
    api_user: `${REACT_APP_API_USER}`,
    token: `${auth.user.token}`,
    user_id: `${auth.user.id}`
  };
};
