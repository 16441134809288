// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from 'src/redux/actions/usersAction';

export const INITIAL_STATE = Immutable({
  usersData: {
    total: 0,
    users: []
  },
  isLoading: false,
  userDetails: {
    rewardsData: {},
    activities: [],
    cardInfo: {}
  },
  offerOptions: [],
  error: null,
  updateError: null,
  errorResetPwd: null,
  issuePointsErr: null,
  isLoadingIssuePoints: false
});

/**
 * Handle get request list user
 * @param {*} state
 * @returns
 */
const getListUsersRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getListUsersSuccess = (state, action) => {
  const {
    data: { total, users }
  } = action;

  return merge(state, {
    usersData: {
      total,
      users
    },
    isLoading: false,
    error: {}
  });
};

const getListUsersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    usersData: {
      total: 0,
      users: []
    },
    error,
    isLoading: false
  });
};

/**
 * get list activities
 * @param {*} state
 * @param {*} action
 * @returns
 */
const getActivitiesRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getActivitiesSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      activities: data
    },
    isLoading: false,
    error: {}
  });
};

const getActivitiesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      activities: []
    },
    isLoading: false
  });
};

/**
 * get list rewards
 * @param {*} state
 * @param {*} action
 * @returns
 */
const getRewardsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getRewardsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      rewardsData: data
    },
    isLoading: false,
    error: {}
  });
};

const getRewardsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      rewards: []
    },
    isLoading: false
  });
};

/**
 * get info to view in card details
 * @param {*} state
 * @param {*} action
 * @returns
 */
const getCardDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCardDetailsSuccess = (state, action) => {
  const { cardInfo } = action.data;

  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      cardInfo
    },
    error: {}
  });
};

const getCardDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      cardInfo: {}
    },
    isLoading: false
  });
};

/**
 * Create new user
 * @param {*} state
 * @returns
 */
const createUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createUserSuccess = (state, action) => {
  const { data } = action;
  const {
    usersData: { users, total }
  } = state;

  return merge(state, {
    usersData: {
      ...state.usersData,
      total: total + 1,
      users: [data, ...users]
    },
    isLoading: false
  });
};

const createUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

/**
 * Create issue card number
 * @param {*} state
 * @returns
 */
const createIssueCardNumberRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createIssueCardNumberSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      cardInfo: data
    },
    isLoading: false
  });
};

const createIssueCardNumberFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

/**
 * Handle request update profile
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updateProfileRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateProfileSuccess = (state, action) => {
  const { data } = action;
  const newUsers = [...state.usersData.users];

  return merge(state, {
    // TODO: mocking users list after update. Call getListUsers when submit update
    usersData: {
      ...state.usersData,
      users: newUsers.map((user) =>
        user.id === data.userID ? { id: user.id, ...data } : user
      )
    },
    isLoading: false,
    updateError: null
  });
};

const updateProfileFailed = (state) => {
  return merge(state, {
    updateError: 'Error',
    isLoading: false
  });
};

/**
 * Get offer for issue point request
 * @param {*} state
 * @returns
 */
const getOfferForIssueRequest = (state) => {
  return merge(state, {
    isLoadingIssuePoints: true,
    error: null,
    errorResetPwd: null,
    issuePointsErr: null
  });
};

const getOfferForIssueSuccess = (state, action) => {
  const { data } = action.data;

  return merge(state, {
    isLoadingIssuePoints: false,
    offerOptions: data || []
  });
};

const getOfferForIssueFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoadingIssuePoints: false,
    offerOptions: [],
    error
  });
};

/**
 * Handle create issue points
 * @param {*} state
 * @returns
 */
const createIssuePointsRequest = (state) => {
  return merge(state, {
    isLoadingIssuePoints: true,
    issuePointsErr: null,
    errorResetPwd: null
  });
};

const createIssuePointsSuccess = (state) => {
  return merge(state, {
    isLoadingIssuePoints: false
  });
};

const createIssuePointsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoadingIssuePoints: false,
    issuePointsErr: error
  });
};

const resetPasswordRequest = (state) => {
  return merge(state, {
    isLoading: true,
    errorResetPwd: null,
    issuePointsErr: null
  });
};

const resetPasswordSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const resetPasswordFailed = (state) => {
  return merge(state, {
    errorResetPwd: 'Error',
    updateError: null,
    error: null,
    errorUpdateProfile: null,
    isLoading: false
  });
};

const cancelUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const cancelUserSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const cancelUserFailed = (state) => {
  return merge(state, {
    errorResetPwd: null,
    updateError: null,
    error: 'error',
    errorUpdateProfile: null,
    isLoading: false
  });
};
export const HANDLERS = {
  [Types.GET_LIST_USERS_REQUEST]: getListUsersRequest,
  [Types.GET_LIST_USERS_SUCCESS]: getListUsersSuccess,
  [Types.GET_LIST_USERS_FAILED]: getListUsersFailed,

  [Types.GET_ACTIVITIES_REQUEST]: getActivitiesRequest,
  [Types.GET_ACTIVITIES_SUCCESS]: getActivitiesSuccess,
  [Types.GET_ACTIVITIES_FAILED]: getActivitiesFailed,

  [Types.GET_REWARDS_REQUEST]: getRewardsRequest,
  [Types.GET_REWARDS_SUCCESS]: getRewardsSuccess,
  [Types.GET_REWARDS_FAILED]: getRewardsFailed,

  [Types.GET_CARD_DETAILS_REQUEST]: getCardDetailsRequest,
  [Types.GET_CARD_DETAILS_SUCCESS]: getCardDetailsSuccess,
  [Types.GET_CARD_DETAILS_FAILED]: getCardDetailsFailed,

  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILED]: createUserFailed,

  [Types.CREATE_ISSUE_CARD_NUMBER_REQUEST]: createIssueCardNumberRequest,
  [Types.CREATE_ISSUE_CARD_NUMBER_SUCCESS]: createIssueCardNumberSuccess,
  [Types.CREATE_ISSUE_CARD_NUMBER_FAILED]: createIssueCardNumberFailed,

  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_FAILED]: updateProfileFailed,

  [Types.GET_OFFER_FOR_ISSUE_REQUEST]: getOfferForIssueRequest,
  [Types.GET_OFFER_FOR_ISSUE_SUCCESS]: getOfferForIssueSuccess,
  [Types.GET_OFFER_FOR_ISSUE_FAILED]: getOfferForIssueFailed,

  [Types.CREATE_ISSUE_POINTS_REQUEST]: createIssuePointsRequest,
  [Types.CREATE_ISSUE_POINTS_SUCCESS]: createIssuePointsSuccess,
  [Types.CREATE_ISSUE_POINTS_FAILED]: createIssuePointsFailed,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILED]: resetPasswordFailed,

  [Types.CANCEL_USER_REQUEST]: cancelUserRequest,
  [Types.CANCEL_USER_SUCCESS]: cancelUserSuccess,
  [Types.CANCEL_USER_FAILED]: cancelUserFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
