// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListUsersRequest: ['params'],
  getListUsersSuccess: ['data'],
  getListUsersFailed: ['error'],

  // get list activities by userID
  getActivitiesRequest: ['params'],
  getActivitiesSuccess: ['data'],
  getActivitiesFailed: ['error'],

  // get list reward by userID
  getRewardsRequest: ['data'],
  getRewardsSuccess: ['data'],
  getRewardsFailed: ['error'],

  // get card details
  getCardDetailsRequest: ['userID'],
  getCardDetailsSuccess: ['data'],
  getCardDetailsFailed: ['error'],

  // create user
  createUserRequest: ['params', 'callback'],
  createUserSuccess: ['data'],
  createUserFailed: ['error'],

  // create card
  createIssueCardNumberRequest: ['params', 'callback'],
  createIssueCardNumberSuccess: ['data'],
  createIssueCardNumberFailed: ['error'],

  // update profile for user
  updateProfileRequest: ['params', 'callback'],
  updateProfileSuccess: ['data'],
  updateProfileFailed: ['error'],

  // get offer for issue points
  getOfferForIssueRequest: [],
  getOfferForIssueSuccess: ['data'],
  getOfferForIssueFailed: ['error'],

  // create issue points for user
  createIssuePointsRequest: ['params', 'callback'],
  createIssuePointsSuccess: ['data'],
  createIssuePointsFailed: ['error'],

  // reset password
  resetPasswordRequest: ['params'],
  resetPasswordSuccess: ['data'],
  resetPasswordFailed: ['error'],

  // cancel user action
  cancelUserRequest: ['params', 'callback'],
  cancelUserSuccess: ['data'],
  cancelUserFailed: ['error']
});
