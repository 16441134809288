import { put, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';

// reducers
import { Types, Creators } from 'src/redux/actions/usersAction';

// apis
import {
  getListUsers,
  getActivities,
  getRewards,
  getCardDetails,
  createUser,
  createCardNumber,
  updateProfileUser,
  getOfferForIssue,
  createIssuePoint,
  resetPassword,
  cancelUser
} from 'src/api-client/usersApi';

// get data authentication for request API

function* getListUsersRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListUsers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListUsersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListUsersFailed(error));
  }
}

/**
 * Handle get data Activities by userID
 * @param {*} action
 */
function* getActivitiesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getActivities, params);
    // format timezone for activities
    // eslint-disable-next-line no-unused-expressions
    response.data &&
      response.data.map((item) => {
        item.date = moment
          .tz(item.date, 'America/New_York')
          .format('MM-DD-yyyy hh:mm:ssa z');
      });
    const newData = sortBy(response.data, ['time']).reverse();
    if (response.status === 200) {
      yield put(Creators.getActivitiesSuccess(newData));
    } else {
      yield put(Creators.getActivitiesFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getActivitiesFailed(error));
  }
}

/**
 * Handle get data rewards by userID
 * @param {*} action
 */
function* getRewardsRequest(action) {
  const { data } = action;

  try {
    const response = yield call(getRewards, data);
    if (response.status === 200) {
      yield put(Creators.getRewardsSuccess(response.data));
    } else {
      yield put(Creators.getRewardsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getRewardsFailed(error));
  }
}

/**
 * Handle get card info to view detail card
 * @param {*} action
 */
function* getCardDetailsRequest(action) {
  const { userID } = action;

  try {
    const response = yield call(getCardDetails, { userID });
    if (response.status === 200) {
      yield put(Creators.getCardDetailsSuccess(response.data));
    } else {
      yield put(Creators.getCardDetailsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCardDetailsFailed(error));
  }
}

/**
 * Handle request creat new user
 * @param {*} action
 */
function* createUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createUser, params);
    yield put(Creators.createUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createUserFailed(error));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle create issue card number
 * @param {*} action
 */
function* createIssueCardNumberRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCardNumber, params);
    yield put(Creators.createIssueCardNumberSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createIssueCardNumberFailed(error));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle update profile for user
 */
function* updateProfileRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateProfileUser, params);
    yield put(Creators.updateProfileSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateProfileFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle get offer for issue points
 */
function* getOfferForIssueRequest() {
  try {
    const response = yield call(getOfferForIssue);
    yield put(Creators.getOfferForIssueSuccess(response));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getOfferForIssueFailed(error || e));
  }
}

/**
 * Handle create issue points
 */
function* createIssuePointsRequest(action) {
  const { params, callback } = action;
  try {
    const response = yield call(createIssuePoint, params);
    yield put(Creators.createIssuePointsSuccess(response));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createIssuePointsFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle reset password request
 */
function* resetPasswordRequest(action) {
  const { params } = action;

  try {
    const response = yield call(resetPassword, params);
    yield put(Creators.resetPasswordSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.resetPasswordFailed(error || e));
  }
}

/**
 * Handle cancel user request
 */
function* cancelUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(cancelUser, params);
    yield put(Creators.cancelUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.cancelUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchUsersRequest() {
  yield takeLatest(Types.GET_LIST_USERS_REQUEST, getListUsersRequest);
  yield takeLatest(Types.GET_ACTIVITIES_REQUEST, getActivitiesRequest);
  yield takeLatest(Types.GET_REWARDS_REQUEST, getRewardsRequest);
  yield takeLatest(Types.GET_CARD_DETAILS_REQUEST, getCardDetailsRequest);
  yield takeLatest(Types.CREATE_USER_REQUEST, createUserRequest);
  yield takeLatest(
    Types.CREATE_ISSUE_CARD_NUMBER_REQUEST,
    createIssueCardNumberRequest
  );
  yield takeLatest(Types.UPDATE_PROFILE_REQUEST, updateProfileRequest);
  yield takeLatest(Types.GET_OFFER_FOR_ISSUE_REQUEST, getOfferForIssueRequest);
  yield takeLatest(Types.CREATE_ISSUE_POINTS_REQUEST, createIssuePointsRequest);
  yield takeLatest(Types.RESET_PASSWORD_REQUEST, resetPasswordRequest);
  yield takeLatest(Types.CANCEL_USER_REQUEST, cancelUserRequest);
}
