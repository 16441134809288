import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/adminUsersAction';

// apis
import {
  getListAdminUsers,
  createAdminUser,
  updateProfileAdminUser,
  resetAdminPassword,
  cancelAdminUser
} from 'src/api-client/adminUsersApi';

// get data authentication for request API

function* getListAdminUsersRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListAdminUsers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListAdminUsersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListAdminUsersFailed(error));
  }
}

/**
 * Handle request creat new user
 * @param {*} action
 */
function* createAdminUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createAdminUser, params);
    yield put(Creators.createAdminUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createAdminUserFailed(error));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle update profile for user
 */
function* updateAdminProfileRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateProfileAdminUser, params);
    yield put(Creators.updateAdminProfileSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateAdminProfileFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle reset password request
 */
function* resetAdminPasswordRequest(action) {
  const { params } = action;

  try {
    const response = yield call(resetAdminPassword, params);
    yield put(Creators.resetAdminPasswordSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.resetAdminPasswordFailed(error || e));
  }
}

/**
 * Handle cancel user request
 */
function* cancelAdminUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(cancelAdminUser, params);
    yield put(Creators.cancelAdminUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.cancelAdminUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchAdminUsersRequest() {
  yield takeLatest(Types.GET_LIST_ADMIN_USERS_REQUEST, getListAdminUsersRequest);
  yield takeLatest(Types.CREATE_ADMIN_USER_REQUEST, createAdminUserRequest);
  yield takeLatest(Types.UPDATE_ADMIN_PROFILE_REQUEST, updateAdminProfileRequest);
  yield takeLatest(Types.RESET_ADMIN_PASSWORD_REQUEST, resetAdminPasswordRequest);
  yield takeLatest(Types.CANCEL_ADMIN_USER_REQUEST, cancelAdminUserRequest);
}
