// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/authAction';

export const INITIAL_STATE = Immutable({
  isAuthenticated: false,
  isLoading: false,
  loginError: null,
  user: {}
});

const loginRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const loginSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    isAuthenticated: true,
    user: data
  });
};

const loginFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    isAuthenticated: false,
    loginError: error
  });
};

/**
 * logout account
 * @param {*} state
 * @returns
 */
export const logoutRequest = (state) => {
  return merge(state, {
    isAuthenticated: false,
    user: {},
    loginError: null
  });
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,
  [Types.LOGOUT_REQUEST]: logoutRequest
};

export default createReducer(INITIAL_STATE, HANDLERS);
