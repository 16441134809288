import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/authAction';

// apis
import { login } from 'src/api-client/usersApi';

/**
 * Worker saga will be fired on LOGIN_ACTION actions
 *
 * @param {Object} action
 * - params: Input data to call API
 * - callback: The callback function handle when finish
 */
function* loginRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(login, params);
    if (!response || !response.data) {
      throw new Error('Login failed.');
    }

    yield put(Creators.loginSuccess(response.data));
    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.loginFailed(error));
    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchAuthRequest() {
  yield takeLatest(Types.LOGIN_REQUEST, loginRequest);
}
