import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardContainer from 'src/containers/DashboardContainer';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/RoutesGuard/AuthGuard';
import GuestGuard from 'src/components/RoutesGuard/GuestGuard';
import { ROUTES } from 'src/constants/routers';

const LoginPage = lazy(() => import('src/containers/auth/LoginContainer'));
const UsersLandingPage = lazy(() =>
  import('src/containers/user/UserLandingContainer')
);
const UserDetailPage = lazy(() =>
  import('src/containers/user/UserDetailContainer')
);
const OffersLandingPage = lazy(() =>
  import('src/containers/offers/OffersLandingContainer')
);

const OfferDetailsPage = lazy(() =>
  import('src/containers/offers/OfferDetailsContainer')
);
const OfferWallsLandingPage = lazy(() =>
  import('src/containers/offerWalls/OfferWallsLandingContainer')
);
const OfferWallDetails = lazy(() =>
  import('src/containers/offerWalls/OfferWallDetailsContainer')
);
const PreshowsLandingPage = lazy(() =>
  import('src/containers/preshows/PreshowsLandingContainer')
);
const PreshowDetailPage = lazy(() =>
  import('src/containers/preshows/PreshowDetailsContainer')
);
const BrandsLandingPage = lazy(() =>
  import('src/containers/brands/BrandsLandingContainer')
);
const RewardsLandingPage = lazy(() =>
  import('src/containers/rewards/RewardsLandingContainer')
);
const SpotsLandingPage = lazy(() =>
  import('src/containers/spots/SpotsLandingContainer')
);
const SpotDetailsPage = lazy(() =>
  import('src/containers/spots/SpotDetailsContainer')
);
const SpotActionsLandingPage = lazy(() =>
  import('src/containers/spotActions/SpotActionsLandingContainer')
);
const SurveysLandingPage = lazy(() =>
  import('src/containers/surveys/SurveysLandingContainer')
);
const SurveyDetailsPage = lazy(() =>
  import('src/containers/surveys/SurveyDetailsContainer')
);
const ItemsLandingPage = lazy(() =>
  import('src/containers/items/ItemsLandingContainer')
);
const ItemDetailsPage = lazy(() =>
  import('src/containers/items/ItemDetailsContainer')
);
const AdminUsersLandingPage = lazy(() =>
  import('src/containers/administrators/AdminUserLandingContainer')
);
const AdminUserDetailPage = lazy(() =>
  import('src/containers/administrators/AdminUserDetailContainer')
);
const NotFoundPage = lazy(() => import('src/pages/NotFoundPage'));

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: ROUTES.NOTFOUND,
    layout: MainLayout,
    component: NotFoundPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTES.LOGIN,
    layout: MainLayout,
    component: LoginPage
  },
  {
    path: ROUTES.HOME,
    guard: AuthGuard,
    layout: DashboardContainer,
    routes: [
      {
        exact: true,
        path: ROUTES.USERS,
        component: UsersLandingPage
      },
      {
        exact: true,
        path: ROUTES.HOME,
        component: () => <Redirect to={ROUTES.USERS} />
      },
      {
        exact: true,
        path: `${ROUTES.USERS}/:id`,
        component: UserDetailPage
      },
      {
        exact: true,
        path: ROUTES.OFFERS,
        component: OffersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.OFFERS}/:id`,
        component: OfferDetailsPage
      },
      {
        exact: true,
        path: ROUTES.OFFER_WALLS,
        component: OfferWallsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.OFFER_WALLS}/:id`,
        component: OfferWallDetails
      },
      {
        exact: true,
        path: ROUTES.PRESHOWS,
        component: PreshowsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.PRESHOWS}/:id`,
        component: PreshowDetailPage
      },
      {
        exact: true,
        path: ROUTES.REWARDS,
        component: RewardsLandingPage
      },
      {
        exact: true,
        path: ROUTES.SPOTS,
        component: SpotsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.SPOTS}/:id`,
        component: SpotDetailsPage
      },
      {
        exact: true,
        path: ROUTES.SPOT_ACTIONS,
        component: SpotActionsLandingPage
      },
      {
        exact: true,
        path: ROUTES.SURVEYS,
        component: SurveysLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.SURVEYS}/:id`,
        component: SurveyDetailsPage
      },
      {
        exact: true,
        path: ROUTES.ITEMS,
        component: ItemsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.ITEMS}/:id`,
        component: ItemDetailsPage
      },
      {
        exact: true,
        path: ROUTES.BRANDS,
        component: BrandsLandingPage
      },
      {
        exact: true,
        path: ROUTES.ADMINISTRATORS,
        component: AdminUsersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.ADMINISTRATORS}/:id`,
        component: AdminUserDetailPage
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardContainer,
    routes: [
      {
        exact: true,
        path: ROUTES.HOME,
        component: () => <Redirect to={ROUTES.USERS} />
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  }
];

export default routes;
