// libs
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { logger } from 'redux-logger';

// constant
import { PERSIST_KEY } from 'src/constants/routers';

// reducer

import rootReducer from './redux/reducers';

// saga
import rootSaga from './redux/saga';

const persistConfig = {
  key: PERSIST_KEY,
  storage,
  whitelist: [
    'authReducer',
    'offersReducer',
    'offerWallsReducer',
    'preshowsReducer'
  ]
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

// only run logger middleware with env development
const middleware = [sagaMiddleware];
if (process.env.REACT_APP_NODE_ENV === 'development') {
  middleware.push(logger);
}

function configureStore() {
  const store = createStore(
    persistedReducer,
    applyMiddleware(sagaMiddleware, logger)
  );
  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
export const persistor = persistStore(store);
