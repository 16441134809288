// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // login
  loginRequest: ['params', 'callback'],
  loginSuccess: ['data', 'email'],
  loginFailed: ['error'],

  // logout
  logoutRequest: []
});
